.my-navbar {
    color: #FFFFFF !important;
    font-size: 14px;
    text-decoration: none;
    width: 130px;
}
.my-navitem{
    background-color: rgba(255, 255, 255, 0.04);
    margin: 5px;
    border-radius: 5px;
    text-align: center;
}

.my-navbar:visited {
    height: 100%;
    font-size: 14px;
}

.active {
    height: 100%;
    color: #FFFFFF!important;
    font-size: 14px;
    border-radius: 5px;
    background-color: #373F23;
}